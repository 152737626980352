.sensorData-tooltip {
  background: #003050 !important;
  max-width: 500px !important;
}

.sensorData-popper {
  border-radius: 6px;
}

.sensorData-container {
  padding: 6px 12px;
  display: flex;
  gap: 20px;
  align-items: center;

  .sensorData-title,
  .sensorData-value {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .sensorData-title {
    color: #fff;
  }

  .sensorData-value {
    color: #007DD1;
    align-items: flex-end;
  }
}