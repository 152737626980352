.invited-users-wrapper {
  .rt-noData.customNoData {
    top: 95px;
    left: calc(50% + 15px);
  }
  .-loading {
    display: none !important;
  }
  .react-tabs__tab-list {
    border: none;
  }
}
