$bg-color: #003050;
$today-color: #ff9e22;
$last-7-days-color: #00f6e6;
$year-color: #f53920;
$circle-marker-color: #fff;
$circle-marker-bg-color: #707070;
$title-light-color: #c6c8d2;
$value-light-color: #fff;
$dark-light-color: #707070;

$status-ok-color: #1BBC9B;
$status-installation-ongoing-color: #3998f9;
$status-connected-color: #4ccd37;
$status-warning-color: #ff9e22;
$status-error-color: #f66400;
$status-not-connected-color: #db422d;

@mixin set-responsive-property($lg, $lx, $xl-2, $value: padding) {
  #{$value}: $lg;

  @media (min-width: 1280px) {
    #{$value}: $lx;
  }

  @media (min-width: 1760px) {
    #{$value}: $xl-2;
  }
}

@mixin adaptive-value($start, $finish, $property: font-size) {
  $addSize: $finish - $start;
  $container: 1920;

  @media (min-width: #{1024 + px}) {
    #{$property}: $start + px;
    #{$property}: calc(#{$start + px} + #{$addSize * 0.7} * (100vw / #{$container}));
  }

  @media (min-width: #{$container + px}) {
    #{$property}: $finish + px;
  }
}