.is-large-button {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.65rem 1rem;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  box-shadow: none;

  &:hover:hover:not(:disabled):not(.active) {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: none;
  }

  &:disabled {
    border-color: #767676 !important;
    color: #767676 !important;
  }
}
